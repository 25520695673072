import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"

export default ({ data }) => {
  const page = data.allWordpressPage.edges[0].node
  return (
    <Layout>
      <SEO title={page.title} />
      <div>
        <h1 className="page-title" dangerouslySetInnerHTML={{ __html: page.title}} />
        <div className="page-content" dangerouslySetInnerHTML={{ __html: page.content }} />
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWordpressPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          content
          slug
        }
      }
    }
  }
`